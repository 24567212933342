.application-filter-modal {
  .form-title {
    width: 215px;
  }

  .input-container,
  .date-picker-container {
    width: 200px;
  }
}

.application-status-select .custom-select__control {
  height: 32px;
  width: 200px;
}

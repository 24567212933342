@import '../../screens/Application/ViewApplication/ViewApplication';

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-right: 2rem;
}

.screen-title {
  font: $h4 $font-medium;
  color: $primary-color;
}

.header-right-part {
  display: flex;
}

.user-dp {
  position: relative;
  height: 3.5rem;
  width: 3.5rem;
  margin-left: 0.625rem;
  object-position: center;
  object-fit: cover;
  border-radius: 0.625rem;
  cursor: pointer;
}

.user-dp:hover {
  opacity: 0.8;
}

.user-settings {
  position: absolute;
  top: 75px;
  right: 30px;
  width: 245px;
  padding: 10px 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 30px #82828229;
  overflow: hidden;
  z-index: 9;

  > div {
    display: flex;
    align-items: center;
    padding: 8px 25px;
    font: $h6 $font-medium;
    color: $primary-color;
    cursor: pointer;

    span {
      margin-right: 10px;
      font-size: 24px;
      color: $secondary-color;
    }
  }
}

.user-settings > div:hover {
  background-color: $background-color;
}

.search {
  width: 13.125rem;
}

.header-search-container {
  position: relative;
  width: 350px;
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 10px;

  > div:first-child {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 5px 10px;
  }

  input {
    flex: 1;
    font: $subtitle $font-regular;
    color: $primary-color;
    border: none;
    outline: none;
  }

  input:focus {
    outline: none;
  }

  .ga-search-icon {
    margin-left: 10px;
    padding: 5px;
    font-size: $h4;
    color: $primary-color;
    cursor: pointer;

    &:hover {
      background-color: $primary-color-1;
      border-radius: 50%;
    }
  }
}

.header-search-container-focused {
  border-color: transparent;
  box-shadow: 0px 0px 10px #3d3d3d29;
}

.got-search-results {
  font: $subtitle $font-regular;
  color: $field-color;
  border-radius: 10px 10px 0 0;
}

.header-search-results {
  position: absolute;
  left: -1px;
  right: -1px;
  background-color: white;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 10px 10px -1px #3d3d3d29;
  -webkit-box-shadow: 0px 10px 10px -1px #3d3d3d29;
  -moz-box-shadow: 0px 10px 13px -1px #3d3d3d29;
  z-index: 1000;

  ul {
    min-height: 70px;
    max-height: 200px;
    margin: 0;
    padding: 0 0 10px 0;
    list-style-type: none;
    overflow: auto;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    cursor: pointer;
    .gs-value {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .gs-tag {
      color: $primary-color;
      padding: 5px 12px;
      border-radius: 5px;
      background: #e2e9f0 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }

  .global-search-loading-text {
    display: block;
    margin: 10px;
    color: $field-color;
  }

  li:hover {
    background-color: #f5f6f8;
  }

  .header-active-search {
    background-color: #f5f6f8;
  }
}

.got-dummy-bottom-block {
  height: 10px;
  width: 100%;
}

.notification {
  position: relative;
  width: 3.5rem;
  margin-left: 10px;
  color: $primary-color;
  height: auto;
}

.notification.true::after {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  height: 14px;
  width: 14px;
  background-color: $secondary-color;
  border-radius: 50%;
  border: 1px solid white;
}

.change-password-dialog {
  width: 40vw;
  max-width: 450px;
  min-width: 400px;
}

.change-password-grid {
  display: grid;
  grid-template-columns: 140px 1fr;
  gap: 10px;
  width: 100%;

  span {
    margin-top: 5px;
    white-space: nowrap;
  }

  input {
    min-width: 200px;
  }
}

.edit-profile-dialog {
  width: 470px;
}
.edit-profile-grid {
  display: grid;
  align-items: center;
  grid-template-columns: 40% 60%;
  gap: 20px 10px;
  width: 100%;
  .form-title {
    font-size: 14px;
    min-width: 100px;
    font-family: 'GoogleSans-Regular', serif;
    font-weight: normal;
  }
  .user-dp {
    height: 40px;
    width: 40px;
    margin-left: 0;
  }
  .user-fields {
    color: $secondary-color;
    max-width: 200px;
    font-size: 14px;
    word-break: break-all;
    overflow: hidden;
    line-height: 32px;
  }
  .input-container {
    max-width: 230px;
    height: 32px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;
  }
}

.notification-set {
  padding: 0 20px;
}

.notification-set-title {
  margin: 5px 0 2px 0;
  font: $h6 $font-medium;
  color: $primary-color;
}

.common-notification-content-box {
  @extend .common-accordion-item-content-box;
  margin-bottom: 8px;
}

.notification-drawer-title {
  display: flex;
  align-items: center;
  span {
    font-size: $h4;
    margin-right: 5px;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header-name,
.tab-content-header {
  font: $h5 $font-medium;
  color: $primary-color;
}

.page-header-button-container {
  display: flex;
}

.notification-item-wrapper {
  margin-bottom: 10px;
  padding: 10px;
  font-size: $text;
  border-radius: 5px;
  cursor: pointer;

  .notification-date-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .notification-date-row-left {
      display: flex;
      align-items: center;
    }
  }

  &.secondary-tag:hover {
    background-color: #f9d0ac;
  }

  &.red-tag:hover {
    background-color: #fec4c4;
  }
}

//alert modal
.alert-details-modal {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;

  .modal-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px 0;
  }

  .alert-type {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 60px;

    .alert-type-right-texts {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
    }
  }

  .alert-details-wrapper {
    padding: 20px 60px;

    .alert-general-details,
    .alert-detail {
      display: grid;
      grid-template-columns: 15% calc(35% - 15px) 15% calc(35% - 15px);
      align-items: center;
      padding: 5px 0;
      gap: 10px;

      >span {
        margin-top: 7px;
        color: $primary-color;
      }

      .alert-detail-value-field {
        padding: 0.344rem;
        min-width: 170px;
        color: $field-color;
      }
    }
  }
}

.notification-clear-all-wrapper {
  position: sticky;
  top: 0;
  padding: 20px 20px 0;
  background: #fff;
  color: $primary-color;
  text-align: right;

  .notification-clear-all-btn {
    width: auto;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background: $background-color;
    }
  }
}

.dropdown-menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.dropdown-menu {
  position: absolute;
  top: 30px;
  min-width: 100px;
  line-height: 1;
  background-color: $background-color;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 1.875rem #82828229;
  overflow: hidden;
  color: $field-color;

  .menu-name {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    padding: 5px 10px;
    border-bottom: 1px solid white;
    cursor: pointer;

    span {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .menu-name:last-child {
    border-bottom: 0;
  }

  .menu-name:hover {
    background-color: $primary-color-1;
  }
}

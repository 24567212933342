@font-face {
  font-family: 'GoogleSans-Medium';
  src: local('GoogleSans-Medium'), url(../assets/fonts/GoogleSans-Medium.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'GoogleSans-Regular';
  src: local('GoogleSans-Regular'), url(../assets/fonts/GoogleSans-Regular.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'GoogleSans-Bold';
  src: local('GoogleSans-Bold'), url(../assets/fonts/GoogleSans-Bold.ttf) format('truetype');
  font-weight: normal;
}
$h1: 3.75rem;
$h2: 3rem;
$h3: 2.125rem;
$h4: 1.5rem;
$h5: 1.25rem;
$h6: 1.125rem;
$title: 1rem;
$subtitle: 0.875rem;
$text: 0.75rem;
$font-regular: 'GoogleSans-Regular', sans-serif;
$font-medium: 'GoogleSans-Medium', sans-serif;
$font-bold: 'GoogleSans-Bold', sans-serif;
$header: $h3 $font-medium;
$field-name: $title $font-medium;
$sub-text: $subtitle $font-regular;

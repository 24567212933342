.gray-label {
  padding: 5px 10px;
  font: $subtitle $font-regular;
  color: $field-color;
  background-color: $background-color;
}

.application-confirmation-step {
  align-items: flex-start !important;
}

.application-confirmation-title {
  padding-top: 5px;
}

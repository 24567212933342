.custom-select {
  position: relative;

  .custom-select__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 270px;
    height: 47px;
    padding: 5px 5px 5px 0;
    background-color: #ffffff;
    border: 1px solid $border-color;
    border-radius: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    .more-text {
      font-size: $text;
      color: $primary-color;
    }

    span {
      color: $placeholder-color;
    }

    .input-container {
      flex: 1;
      width: fit-content;
    }

    .input-container,
    input {
      font: $subtitle $font-regular;
      color: $field-color;
      border: none;
      outline: none;
    }

    input:focus ~ span {
      color: $field-color;
    }
  }

  .custom-select-dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 0;
    background-color: #ffffff;
    border: 0px solid $border-color;
    border-radius: 5px;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    z-index: 9999;

    ul {
      margin: 5px 0;
      padding: 0;
      background-color: #ffffff;
      list-style-type: none;
    }

    li {
      padding: 7px 10px;
      font: $subtitle $font-regular;
      color: $field-color;
      cursor: pointer;
    }

    li:hover {
      background-color: $background-color;
    }
  }

  ul.selected-list {
    margin: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      background-color: $primary-color;
      border-bottom: 0.03px solid #ffffff;
      border-right: 2px solid #ffffff;

      span {
        padding-left: 10px;
        font-size: $title;
      }
    }
  }
  .selected-list li:hover {
    background-color: $primary-variant-color;
  }
}

.custom-select-dropdown.active-custom-select-dropdown {
  max-height: 40vh;
  border-width: 1px;
  overflow-y: auto;
}

.no-option-text {
  padding: 10px;
  font-size: $subtitle;
  color: $field-color;
  text-align: center;
}

.code-container {
  display: flex;
  align-items: center;
  margin-top: 10px;

  input {
    flex: 0;
    width: 56px !important;
    height: 56px !important;
    margin-right: 0.625rem;
    font: $h5 $font-regular;
    color: $primary-color;
    border: 0.063rem solid $border-color !important;
    border-radius: 0.625rem !important;
    outline: none;
  }

  input::placeholder {
    font: $h5 $font-medium;
    color: $primary-color;
  }
}

.credit-limits-details {
  display: grid !important;
  grid-template-columns: 150px calc(50% - 145px) 150px calc(50% - 145px);
  padding: 25px 30px;
  gap: 15px 5px;
  width: 100%;

  > .title {
    margin-top: 7px;
    color: $primary-color;
  }

  > .value {
    width: 70%;
    min-height: 32px;
    min-width: 170px;
    padding: 6px 8px;
    color: $field-color;
  }
}

.otp-row {
  display: flex;

  > div {
    justify-content: center;
    flex: 1 0 calc(16% - 0.563rem);
    margin-right: 0.938rem;
  }
  input {
    flex: 0;
    width: 1.25rem;
    font: $title $font-regular;
    color: $primary-color;
  }

  input::placeholder {
    color: $primary-color;
  }
}

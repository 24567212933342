.button.icon-button.download-decision-letter-icon {
  display: inline-flex;
  padding: 3px 15px;
  background-color: #ffffff;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid $primary-color;
}
.button.icon-button.download-decision-letter-icon:hover {
  background-color: $border-color;
}

.button.icon-button.download-decision-letter-icon.disable-download-button {
  background-color: $border-color;
  opacity: 0.3;
}

.credit-limit-custom-select .custom-select__control {
  height: 32px;
  width: 200px;
}

.disable-delete-button {
  opacity: 0.3;
  cursor: not-allowed;
}
.disclaimer-title {
  font: 14px $font-medium;
  color: rgba(0, 58, 120, 0.85);
  padding-left: 10px;
}
.disclaimer-body {
  padding: 5px 10px 0;
  text-align: justify;
  color: $field-color;
  font-size: 14px;
}

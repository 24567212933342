.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: screen;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.drawer-container {
  position: fixed;
  top: 15px;
  right: 0;
  bottom: 0;
  width: 0;
  height: calc(100vh - 15px);
  background-color: white;
  border-radius: 10px 0 0 0;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 30px #7272724d;
  overflow: hidden;
  z-index: 11;
}

.drawer-wrapper {
  min-width: 550px;
}

.drawer-opened {
  width: 550px;
}

.drawer-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  min-width: 100%;
  padding: 20px 40px 20px 20px;
  font: $h5 $font-medium;
  color: white;
  background-color: $primary-color;

  * {
    width: fit-content !important;
    overflow: hidden;
    white-space: nowrap;
  }
}

.close-drawer {
  padding: 3px;
  font-size: 14px;
  font-weight: 700;
  color: $primary-color;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.drawer-content {
  max-height: calc(100vh - 75px);
  overflow-y: auto;
}

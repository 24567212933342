@import 'color-palette';
@import 'typography';
@import 'common-class';
@import 'basic-styles';
@import 'node_modules/react-datepicker/dist/react-datepicker';
@import '~rc-tooltip/assets/bootstrap.css';
@import 'modified-library';

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.f-14 {
  font-size: $subtitle;
}

.font-primary {
  color: $primary-color;
}

.font-secondary {
  color: $secondary-color;
}

.font-placeholder {
  color: $placeholder-color;
}

.font-field {
  color: $field-color;
}

.font-danger {
  color: $error-color;
}

.d-flex {
  display: flex;
}

.just-center {
  justify-content: center;
}

.just-bet {
  justify-content: space-between !important;
}

.just-end {
  justify-content: flex-end !important;
}

.align-center {
  align-items: center;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.ml-5 {
  margin-left: 0.313rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.ml-15 {
  margin-left: 0.938rem;
}

.mr-5 {
  margin-right: 0.313rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mr-15 {
  margin-right: 0.938rem;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-15 {
  margin-top: 0.938rem;
}
.mt-20 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 0.313rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-15 {
  margin-bottom: 0.938rem;
}

.mb-20 {
  margin-bottom: 20px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-background-color {
  background-color: $background-color;
}

.form-title {
  width: 80px;
  font: $subtitle $font-medium;
  color: $primary-color;
}

.no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: 20px;
  font: $subtitle $font-regular;
  color: $primary-color;
}

.common-detail-container {
  width: 100%;
  margin-top: 25px;
  padding: 20px;
  font: $sub-text;
  background-color: white;
  border-radius: 10px;
}

.common-detail-grid {
  display: grid;
  grid-template-columns: calc(50% - 5%) calc(50% - 5%);
  grid-template-areas: 'r1c1 r1c2' 'r2c1 r2c2' 'r3c1 r3c1';
  gap: 15px 10%;
}

.common-detail-field.user-select-client {
  grid-column: 1 / span 2;
}

.common-detail-field {
  display: grid;
  align-items: center;
  grid-template-columns: 175px 1fr;
}

.common-detail-title {
  min-width: 120px;
  margin-right: 20px;
  color: $primary-color;
}

.module-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.module {
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;

  label {
    margin: 5px 0;
  }
}

.module-title {
  margin-bottom: 10px;
  font: $h5 $font-medium;
  color: $primary-color;
}

.checkbox-disabled {
  * {
    cursor: not-allowed;
  }
}

.add-user-detail-container span {
  padding: 0 10px;
  font: $subtitle $font-medium;
  color: $field-color;
}

.debtors-modal-header {
  text-align: left !important;
}

.view-debtor-detail-container {
  @extend .add-overdue-content;
  gap: 10px 5%;
  width: 100%;
  padding: 15px;
}

.view-debtor-value {
  min-height: 32px;
  padding: 0.344rem;
  font: $subtitle $font-medium;
  color: $field-color;
}

.view-debtor-detail-field-container {
  display: grid;
  grid-template-columns: 155px 1fr;
}

.view-debtor-detail-title {
  margin-top: 5px;
  color: $primary-color;
}

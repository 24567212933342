/* completely hiding radio button */
input[type='radio'] {
  display: none;
}

/* simulate radiobutton appearance using pseudo selector */
input[type='radio'] + label:before {
  content: '';
  /* create custom radiobutton appearance */
  display: inline-block;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  padding: 3px;
  margin-right: 5px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid $placeholder-color;
  background-color: $placeholder-color;
  border-radius: 50%;
}

input[type='radio']:disabled ~ .radio-button {
  cursor: not-allowed;

  &:hover:before {
    border: 1px solid $border-color;
    background-color: #e7e6e7;
  }
}

/* appearance of checked radiobutton */
input[type='radio']:checked + .radio-button:before,
input[type='radio']:checked:disabled + .radio-button:before {
  background-color: $primary-color;
  border-color: $primary-color;
}

/* resetting default box-sizing */
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* optional styles for centering radio buttons */
.radio-button {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  color: $field-color;
  cursor: pointer;
}

.radio-button:hover:before {
  background-color: $field-color;
  border-color: $field-color;
}

.main-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url('../../../../assets/images/login-background.jpg');
  background-size: cover;
  background-position: center;
}

.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 43.75rem;
  height: 32.813rem;
  padding: 3rem;
  background-color: #ffffff;
  border-radius: 0.625rem;
  border: 2px solid white;
  overflow: hidden;

  > div {
    width: 80%;
    max-width: 420px;
  }
}

.fixed-right-strips {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 3rem;
  height: 100%;
}

.blue-strip,
.orange-strip,
.green-strip {
  width: 1.5rem;
  height: 100%;
  background-color: $primary-color;
  border-left: 0.063rem solid #ffffff;
}

.orange-strip {
  background-color: $secondary-color;
}

.green-strip {
  background-color: $third-color;
}

.logo {
  height: 3.125rem;
}

.header {
  font: $header;
  color: $secondary-color;
  line-height: 2.75rem;

  div:first-of-type {
    color: $primary-color;
  }
}

.login-field-name {
  margin: 0.938rem 0 0.313rem 0;
  font: $field-name;
  color: $field-color;
}

.login-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  padding: 1.125rem;
  border: 0.063rem solid $border-color;
  border-radius: 0.625rem;

  span {
    width: 3.5rem;
    color: $primary-color;
  }

  input {
    flex: 1;
    border: none;
    outline: none;
  }
}

.login-action-row {
  display: flex;
  justify-content: flex-end;
  margin: 0.938rem 0 1.25rem 0;

  button {
    font: $sub-text;
    color: $primary-color;
    text-decoration: underline;
  }
}

.gray-logo {
  position: absolute;
  bottom: 0.625rem;
  right: 3.438rem;
  height: 5.1rem;
  width: 6rem;
  opacity: 0.1;
}

.login-input-icon {
  font-size: 1.5rem;
  color: $primary-color;
}

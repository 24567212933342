.big-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  padding: 1.125rem;
  background-color: #ffffff;
  border: 0.063rem solid $border-color;
  border-radius: 0.625rem;

  span {
    width: 2.5rem;
  }

  input {
    flex: 1;
    border: none;
    outline: none;
  }
}

.big-input {
  font: $title $font-regular;
  color: $field-color !important;
}

.big-input:-webkit-autofill,
.big-input:-webkit-autofill:hover,
.big-input:-webkit-autofill:focus,
.big-input:-webkit-autofill:active {
  font: $title $font-regular !important;
  transition: background-color 5000s;
  -webkit-text-fill-color: $field-color !important;
}

.suffix {
  text-align: right;
  cursor: pointer;
}

.common-white-container.support-grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
  width: 100%;
  justify-content: center;
  padding: 30px;
  font: $subtitle $font-regular;
  color: $primary-color;
  overflow: hidden;

  .title {
    font-family: $font-regular;
    opacity: 0.5;
    color: $field-color;
  }
}

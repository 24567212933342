.documents-table {
  width: 100%;
  font-size: $subtitle;
  color: $field-color;
  border-collapse: collapse;
  td,
  th {
    max-width: 350px;
    padding: 0.313rem 1.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.documents-table tr:nth-child(even) {
  background-color: $background-color;
}

.documents-table tr:nth-child(odd) {
  background-color: #ffffff;
}
.document-upload-popup-container {
  display: grid;
  align-items: center;
  grid-template-columns: 180px 2fr;
  gap: 15px 5px;

  > span {
    color: $primary-color;
  }

  .react-dropdown-select,
  .upload-document-input {
    width: 180px;
  }
  .switch-container {
    width: 180px;
  }
  .document-upload-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 0.063rem solid #e7ecf2;
    height: 32px;
    width: 180px;
    button {
      border: none;
      color: #b2b9bf;
      background: none;
    }
    p {
      color: #b2b9bf;
    }
  }
}
.upload-document-modal {
  width: 60vw;
}

.upload-document-row {
  margin-bottom: 30px;
}
